<section
  class="content col-12 d-flex justify-content-center align-items-center"
  #about
>
  <div style="position: absolute; top: 0; left: 0" #aboutHook></div>
  <div class="container">
    <h1 class="display-5 mb-5">About Me</h1>
    <p class="lead">
      Hi, I’m Santhosh, a UI/UX developer hailing from Chennai, India. After
      graduating from college with a degree in software engineering, I started
      my career as a web engineer at
      <a
        class="inline-link"
        href="https://zs.com"
        target="_blank"
        rel="noopener"
      >
        ZS Associates </a
      >. There, I gained experience in a wide range of web technologies,
      including JavaScript, TypeScript, HTML, CSS/Sass, Angular, and React. I'm
      now a senior software engineer at
      <a
        class="inline-link"
        href="https://pinelabs.com"
        target="_blank"
        rel="noopener"
      >
        Pine Labs </a
      >, where I'm working on building cutting-edge FinTech solutions.
    </p>
    <p class="lead">
      I work to make a better web; one that is fast, good-looking, easy to use,
      accessible to all, and frustration-free. My interests expand across the
      frontend spectrum and I aspire to work with ambitious and positive people.
    </p>
    <p class="lead">
      I'm always up for new projects and opportunities. Have you got something
      in the works and want to join forces?
      <a class="inline-link" role="button" (click)="scrollToElement(4)">
        Let's connect!
      </a>
      You can reach me via email, phone, smoke signals, messenger pigeons, or
      even sky-writing. Although, I prefer the first method.
    </p>
    <!-- <img
      src="assets/SanthoshV.jpg"
      alt="Santhosh"
      class="rounded-circle"
      height="250px"
    /> -->
  </div>
</section>

<section
  class="content col-12 d-flex justify-content-center align-items-center"
  #projects
>
  <div style="position: absolute; top: 0; left: 0" #projectsHook></div>
  <app-projects></app-projects>
</section>

<section
  class="content col-12 d-flex justify-content-center align-items-center"
  #contact
>
  <div style="position: absolute; top: 0; left: 0" #contactHook></div>
  <app-contact></app-contact>
</section>

<div id="menu-btn">
  <button
    type="button"
    class="btn btn-dark"
    name="menu"
    (click)="toggleMenuOnButtonClick($event)"
  >
    <label for="menu-toggle">
      <input type="checkbox" id="menu-toggle" [(ngModel)]="isMenuOpen" />
      <span></span>
      <span></span>
      <span></span>
    </label>
  </button>
</div>

<app-side-bar
  [state]="isMenuOpen ? 'open' : 'closed'"
  (itemClicked)="navItemClickHandler($event)"
></app-side-bar>
