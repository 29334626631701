<!-- <img id="avtar" src="assets/MyAvtar.png" alt="" height="25%" /> -->
<section class="content col-12" id="intro">
  <div
    class="container text-right d-flex flex-column align-items-end justify-content-center position-relative"
  >
    <h1 class="display-4 is-link font-weight-bold title-font mb-0">
      👋🏼 I'm Santhosh.
    </h1>
    <p class="lead mb-5">I build app & stuff.</p>
    <button class="btn btn-text-arrow-down" (click)="onButtonClick(1)">
      Learn more
    </button>
    <a
      class="scroll-down-link scroll-down-arrow"
      [ngClass]="{ 'd-none': !showScrollAnchor }"
      (click)="scrollDown()"
    ></a>
  </div>
</section>
