import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
})
export class IntroComponent {
  @Output() itemClicked: EventEmitter<number> = new EventEmitter<number>();

  showScrollAnchor = false;

  onButtonClick(id: number) {
    this.itemClicked.emit(id);
    setTimeout(() => {
      this.itemClicked.emit(0);
    });
  }

  scrollDown(px = 100): void {
    window.scrollTo({
      top: window.scrollY + px,
      behavior: 'smooth',
    });
  }

  // @HostListener('window:scroll', ['$event'])
  // checkOffsetTop() {
  //   this.showScrollAnchor = window.pageYOffset < 100;
  // }
}
