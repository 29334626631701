<footer class="content">
  <div class="w-100 end-section shadow"></div>
  <div class="container py-2">
    <div class="row no-gutter">
      <div
        class="col-12 col-md-6 d-flex justify-content-center justify-content-md-start align-items-end p-0"
      >
        <div class="d-flex">
          <a
            class="btn btn-light btn-custom-icon rounded-circle mx-1"
            role="button"
            name="instagram"
            aria-label="instagram"
            [href]="links.instagram"
            target="_blank"
            rel="noopener"
            ><i class="fa fa-instagram" aria-hidden="true"></i
          ></a>
          <a
            class="btn btn-light btn-custom-icon rounded-circle mx-1"
            role="button"
            name="facebook"
            aria-label="facebook"
            [href]="links.facebook"
            target="_blank"
            rel="noopener"
            ><i class="fa fa-facebook" aria-hidden="true"></i
          ></a>
          <a
            class="btn btn-light btn-custom-icon rounded-circle mx-1"
            role="button"
            name="twitter"
            aria-label="twitter"
            [href]="links.twitter"
            target="_blank"
            rel="noopener"
            ><i class="fa fa-twitter" aria-hidden="true"></i
          ></a>
          <a
            class="btn btn-light btn-custom-icon rounded-circle mx-1"
            role="button"
            name="linkedin"
            aria-label="linkedin"
            [href]="links.linkedin"
            target="_blank"
            rel="noopener"
            ><i class="fa fa-linkedin" aria-hidden="true"></i
          ></a>
          <a
            class="btn btn-light btn-custom-icon rounded-circle mx-1"
            role="button"
            name="mail"
            aria-label="mail"
            [href]="email.mailto"
            target="_blank"
            rel="noopener"
            ><i class="fa fa-envelope" aria-hidden="true"></i
          ></a>
        </div>
      </div>
      <div
        class="col-12 col-md-6 d-flex justify-content-center justify-content-md-end align-items-end"
      >
        <h6 class="footer__credits">
          Made with
          <i class="fa fa-heart text-danger" aria-hidden="true"></i>
          by Santhosh.
          <!-- Built by Santhosh 🔥 -->
        </h6>
      </div>
    </div>
  </div>
  <!-- <h3 class="font-weight-bold mx-auto mb-4">
      &lt;
      <img src="assets/SanthoshLogo.png" alt="Santhosh" height="50px" />
      &gt;
    </h3> -->
  <!-- <p class="lead text-center mb-4">
      I help make the products we all interact with better and I love doing it.
    </p> -->
</footer>
