import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable()
export class HttpService {
  baseURL = environment.baseURL;
  constructor(private http: HttpClient) {}

  get(endPoint: string): Observable<any> {
    return this.http.get(this.baseURL + endPoint).pipe(
      map((responseData) => {
        return JSON.parse(JSON.stringify(responseData));
      })
    );
  }

  post(endPoint: string, payload: any): Observable<any> {
    return this.http.post(this.baseURL + endPoint, payload).pipe(
      map((responseData) => {
        return JSON.parse(JSON.stringify(responseData));
      })
    );
  }
}
