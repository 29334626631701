import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EMAIL } from '../home/home.model';
import { SOCIAL_LINKS } from './../home/home.model';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
  animations: [
    trigger('slide', [
      state('open', style({ transform: 'translateX(0)' })),
      state('closed', style({ transform: 'translateX(100%)' })),
      transition('* => *', animate(400)),
    ]),
  ],
})
export class SideBarComponent {
  @Input() state: 'open' | 'closed';
  @Output() itemClicked = new EventEmitter<number>();

  email = EMAIL;
  links = SOCIAL_LINKS;

  onNavItemClick(id: number) {
    this.itemClicked.emit(id);
    setTimeout(() => {
      this.itemClicked.emit(-1);
    });
  }
}
