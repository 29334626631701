<div id="side-bar" [@slide]="state">
  <div class="container h-100 position-relative">
    <a class="navbar-brand">
      <h3 class="font-weight-bold m-0" (click)="onNavItemClick(0)">
        &lt;
        <img
          src="assets/SanthoshLogo.png"
          alt="Santhosh"
          height="50"
          width="100"
        />
        &gt;
      </h3>
    </a>
    <div class="row h-100 py-5 no-gutters">
      <div class="col-12 col-md-6 d-flex align-items-center pt-5 pt-md-0">
        <ul
          class="nav flex-column pl-md-5 justify-content-center position-relative my-auto"
        >
          <li class="ul-title mb-0 ml-3 ml-md-0">MENU</li>
          <li class="nav-item">
            <h2 class="font-weight-bold m-0">
              <a class="nav-link custom-nav-link" (click)="onNavItemClick(1)">
                About Me
              </a>
            </h2>
          </li>
          <!-- <li class="nav-item">
            <h2 class="font-weight-bold m-0">
              <a class="nav-link custom-nav-link" (click)="onNavItemClick(2)">
                What I Do
              </a>
            </h2>
          </li> -->
          <li class="nav-item">
            <h2 class="font-weight-bold m-0">
              <a class="nav-link custom-nav-link" (click)="onNavItemClick(3)">
                Some things I've Built
              </a>
            </h2>
          </li>
          <li class="nav-item">
            <h2 class="font-weight-bold m-0">
              <a class="nav-link custom-nav-link" (click)="onNavItemClick(4)">
                Get In Touch
              </a>
            </h2>
          </li>
        </ul>
      </div>
      <div class="contact-details col-12 col-md-6 pb-5 pb-md-0">
        <ul
          class="nav flex-md-column col-12 col-md-6 pl-md-3 pb-md-5 h-100 ml-auto justify-content-start justify-content-md-end align-items-end"
        >
          <li class="nav-item m-1">
            <a
              class="text-decoration-none nav-link btn btn-link"
              role="button"
              name="instagram"
              aria-label="instagram"
              [href]="links.instagram"
              target="_blank"
              rel="noopener"
            >
              <i class="fa fa-fw fa-instagram" aria-hidden="true"></i>
            </a>
          </li>
          <li class="nav-item m-1">
            <a
              class="text-decoration-none nav-link btn btn-link"
              role="button"
              name="facebook"
              aria-label="facebook"
              [href]="links.facebook"
              target="_blank"
              rel="noopener"
            >
              <i class="fa fa-fw fa-facebook" aria-hidden="true"></i>
            </a>
          </li>
          <li class="nav-item m-1">
            <a
              class="text-decoration-none nav-link btn btn-link"
              role="button"
              name="twitter"
              aria-label="twitter"
              [href]="links.twitter"
              target="_blank"
              rel="noopener"
            >
              <i class="fa fa-fw fa-twitter" aria-hidden="true"></i>
            </a>
          </li>
          <li class="nav-item m-1">
            <a
              class="text-decoration-none nav-link btn btn-link"
              role="button"
              name="linkedin"
              aria-label="linkedin"
              [href]="links.linkedin"
              target="_blank"
              rel="noopener"
            >
              <i class="fa fa-fw fa-linkedin" aria-hidden="true"></i>
            </a>
          </li>
          <li class="nav-item m-1">
            <a
              class="text-decoration-none nav-link btn btn-link"
              role="button"
              name="mail"
              aria-label="mail"
              [href]="email.mailto"
              target="_blank"
              rel="noopener"
            >
              <i class="fa fa-fw fa-envelope" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="quote px-3 px-sm-4 px-md-0">
      <p class="text-md-left m-0 is-link">
        <em>
          I help make the products we all interact with better and I love doing
          it.
        </em>
      </p>
    </div>
  </div>
</div>
