<div class="container" id="projects-container">
  <h1 class="display-5 mb-5">Some Things I've Built</h1>
  <div class="card-columns project-wrapper">
    <ng-container *ngFor="let project of projects">
      <div class="card mb-4">
        <div class="card-body position-relative">
          <h5 class="card-title font-weight-bold is-link w-75">
            {{ project.title }}
          </h5>
          <ul id="card-action-buttons" class="nav">
            <!-- <li class="nav-item">
              <a
                class="text-decoration-none nav-link btn btn-link px-2"
                role="button"
                name="instagram"
                aria-label="instagram"
                [href]="project.link"
                target="_blank"
                rel="noopener"
              >
                <i class="fa fa-code" aria-hidden="true"></i>
              </a>
            </li> -->
            <li class="nav-item">
              <a
                class="text-decoration-none nav-link btn btn-link px-2"
                role="button"
                name="instagram"
                aria-label="instagram"
                [href]="project.link"
                target="_blank"
                rel="noopener"
              >
                <i class="fa fa-external-link" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
          <!-- <div class="web-page mb-3">
            <div class="browser-mockup"></div>
            <img
              class="card-img-bottom"
              [src]="'assets/' + project.image"
              [alt]="project.title"
            />
          </div> -->
          <p class="card-text mb-3">
            {{ project.description }}
          </p>

          <ul class="nav mx-n1">
            <ng-container
              *ngFor="let item of project.techStack; let last = last"
            >
              <li class="p-1">
                <small>{{ item }}</small>
              </li>
              <li class="p-1" *ngIf="!last">
                <small>|</small>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>
  <p class="text-center mt-4 mb-0">(more coming soon)</p>
</div>
