import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { PERSONAL_PROJECTS } from './home.model';
import { HttpService } from './home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements AfterViewInit, OnChanges {
  @Input() contentId: number;

  @Output() activeContent: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('about') content1: ElementRef;
  // @ViewChild('work') content2: ElementRef;
  @ViewChild('projects') content3: ElementRef;
  @ViewChild('contact') content4: ElementRef;
  @ViewChild('aboutHook') contentHook1: ElementRef;
  // @ViewChild('workHook') contentHook2: ElementRef;
  @ViewChild('projectsHook') contentHook3: ElementRef;
  @ViewChild('contactHook') contentHook4: ElementRef;

  currentActive = 0;
  contentOffset1: number = null;
  // contentOffset2: number = null;
  contentOffset3: number = null;
  contentOffset4: number = null;

  isAPIAvailable = false;
  isMenuOpen = false;

  showTop = false;

  personalProjects = PERSONAL_PROJECTS;

  constructor(private httpService: HttpService) {}

  ngAfterViewInit() {
    this.contentOffset1 = this.content1.nativeElement.offsetTop - 100;
    // this.contentOffset2 = this.content2.nativeElement.offsetTop - 100;
    this.contentOffset3 = this.content3.nativeElement.offsetTop - 100;
    this.contentOffset4 = this.content4.nativeElement.offsetTop - 100;
    this.httpService.get('start').subscribe((response) => {
      this.isAPIAvailable = response.result === 'succeeded';
      console.log(this.isAPIAvailable);
    });
  }

  ngOnChanges(): void {
    this.scrollToElement(this.contentId);
  }

  navItemClickHandler(elementId: number): void {
    this.isMenuOpen = false;
    setTimeout(() => {
      // $('.scroll-down-arrow').css('visibility', 'visible');
      this.scrollToElement(elementId);
    }, 300);
  }

  scrollToElement(element: number): void {
    switch (element) {
      case 0: {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
      case 1: {
        this.contentHook1.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
        break;
      }
      case 2: {
        // this.contentHook2.nativeElement.scrollIntoView({
        //   behavior: 'smooth',
        //   block: 'start',
        //   inline: 'nearest',
        // });
        break;
      }
      case 3: {
        this.contentHook3.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
        break;
      }
      case 4: {
        this.contentHook4.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
        break;
      }
    }
  }

  // toggleMenu($event: boolean): void {
  //   console.log('model change');
  //   if ($event) {
  //     // $('body').css('overflow-y', 'hidden');
  //     $('.scroll-down-arrow').css('visibility', 'hidden');
  //   } else {
  //     $('.scroll-down-arrow').css('visibility', 'visible');
  //     // $('body').css('overflow-y', 'auto');
  //   }
  // }

  toggleMenuOnButtonClick($event): void {
    $event.preventDefault();
    this.isMenuOpen = !this.isMenuOpen;
    // if (this.isMenuOpen) {
    //   // $('body').css('overflow-y', 'hidden');
    //   $('.scroll-down-arrow').css('visibility', 'hidden');
    // } else {
    //   $('.scroll-down-arrow').css('visibility', 'visible');
    //   // $('body').css('overflow-y', 'auto');
    // }
  }

  // @HostListener('window:scroll', ['$event'])
  // checkOffsetTop() {
  //   if (window.pageYOffset < this.contentOffset1) {
  //     this.showTop = false;
  //   } else if (
  //     window.pageYOffset >= this.contentOffset1 &&
  //     window.pageYOffset < this.contentOffset2
  //   ) {
  //   } else if (
  //     window.pageYOffset >= this.contentOffset1 &&
  //     window.pageYOffset < this.contentOffset3
  //   ) {
  //     this.currentActive = 1;
  //     this.showTop = false;
  //   } else if (
  //     window.pageYOffset >= this.contentOffset2 &&
  //     window.pageYOffset < this.contentOffset3
  //   ) {
  //     this.currentActive = 2;
  //     this.showTop = true;
  //   } else if (
  //     window.pageYOffset >= this.contentOffset3 &&
  //     window.pageYOffset < this.contentOffset4
  //   ) {
  //     this.currentActive = 3;
  //     this.showTop = true;
  //   } else {
  //     this.currentActive = 0;
  //     this.showTop = true;
  //   }
  //   this.activeContent.emit(this.currentActive);
  // }
}
