import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  activeItemId: number;
  activeContentId: number;

  itemClickHandler(id: number) {
    this.activeItemId = id;
  }

  setActiveContent(id: number) {
    this.activeContentId = id;
  }
}
