import { Component, OnInit } from '@angular/core';
import { PERSONAL_PROJECTS } from '../home/home.model';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent {
  projects = PERSONAL_PROJECTS;
}
