export const PERSONAL_PROJECTS = [
  {
    title: 'Chat Application',
    description: `
      A real-time chat application. A personal project that birthed to understand the workings of web sockets.
      `,
    techStack: ['Angular', 'Node.js', 'Express', 'MongoDB', 'Bootstrap'],
    image: 'TXTR.png',
    link: 'https://project-txtr.web.app',
    repository: 'https://github.com/santhoshvenkateswaran/txtr-frontend',
  },
  // {
  //   title: 'An e-commerce store',
  //   description: `
  //     This is a basic online store application. The user part and admin part were built separately. The user can browse
  //     through the products, add products to cart and checkout. (payment portal isn’t built yet). The admin on the other
  //     side could update the products catalogue and check order list. This is still work in-progress. I’m slowly building
  //      it as and when I find time.
  //      `,
  //   techStack: [
  //     'React',
  //     'Node.js',
  //     'Express',
  //     'MongoDB',
  //     'Ant Design',
  //     'Firebase',
  //   ],
  //   image: 'unsaved-whatsapp.png',
  //   link: 'https://shop-axe.web.app',
  //   repository: 'https://github.com/santhoshvenkateswaran/aggrow',
  // },
  {
    title: 'WhatsApp Unsaved Number',
    description: `
      I always found adding a number to my contact list just to text them on WhatsApp a real pain. Since there were no easy
      alternatives to it, I thought I’d build something to make our life easier. This app lets you text any number on
      WhatsApp without having to add them to your contacts. Since this is a PWA, one could install this app on their
      phones/laptops too.
      `,
    techStack: ['Angular', 'Bootstrap', 'Firebase'],
    image: 'Aggrow.png',
    link: 'https://wa-unsaved.web.app',
    repository: 'https://github.com/santhoshvenkateswaran/unsaved-whatsapp',
  },
  {
    title: 'A Crop Recommendation System',
    description: `
      A user-friendly recommendation system for predicting the perfect crop according to Rainfall, Temperature & Ph in your
      desired land. This application uses a supervised Machine Learning Ensemble Model of algorithms in the backend. Both
      the front-end and the back-end have been hosted on Heroku.
      `,
    techStack: ['Angular', 'Flask', 'Heroku', 'Bootstrap'],
    image: 'TXTR.png',
    link: 'https://project-aggrow.herokuapp.com/',
    repository: 'https://github.com/santhoshvenkateswaran/aggrow',
  },
  {
    title: 'Word Generator',
    description: `
      A random word generator application that I built for a rapper friend of mine.
      It gives you an option to adjust the time interval in which a new word gets generated.
      `,
    techStack: ['Angular', 'Firebase', 'Ant Design'],
    image: '',
    link: 'https://desifreestyle.web.app/',
    repository: 'https://github.com/santhoshvenkateswaran/word-generator',
  },
  // {
  //   title: 'Investments Tracker',
  //   description: `
  //     This is still work in-progress. An application that lets you track your investments like stocks, mutual funds,
  //     bonds, gold, etc. in one place. There aren’t many APIs that would provide real time rates. If you know any, please
  //     do drop a message!
  //     `,
  //   techStack: ['Angular', 'Ant Design', 'Firebase'],
  //   image: 'Wildfire.png',
  //   link: 'https://wa-unsaved.web.app',
  //   repository:
  //     'https://bitbucket.org/santhoshvenkateswaran/investments-tracker',
  // },
];

export const EMAIL = {
  text: 'hello@santhosh.page',
  mailto: 'mailto:hello@santhosh.page',
};

export const SOCIAL_LINKS = {
  instagram: 'https://www.instagram.com/IvanSanthosh/',
  facebook: 'https://www.facebook.com/IvanSanthosh/',
  twitter: 'https://www.twitter.com/IvanSanthosh/',
  linkedin: 'https://www.linkedin.com/in/santhoshvenkateswaran/',
  resume: 'https://www.instagram.com/IvanSanthosh/',
};
