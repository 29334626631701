<div class="container" id="contact-container">
  <h1 class="display-5 mb-5">Get In Touch</h1>
  <div class="row no-gutter">
    <h5 class="col-12 col-md-10">
      If you have a project in mind or are simply interested in finding out
      more, drop me a message, and let’s get things moving.
    </h5>
    <form
      class="col-12 col-md-6 pt-3 mb-5"
      [formGroup]="form"
      (ngSubmit)="addFormDetails(form.value)"
    >
      <p class="text-warning text-center" *ngIf="isError">
        There was an error with your submission.<br />Please try again or
        contact Santhosh directly at&nbsp;
        <a class="text-decoration-none" [href]="email.mailto">
          {{ email.text }}
        </a>
      </p>
      <p class="is-link text-center" *ngIf="messageSent.length > 0 && !isError">
        {{ this.messageSent }}
        <br />
        Your message has been received and I will get back to you shortly.
      </p>
      <div class="form-group">
        <label for="name" class="d-none">Name</label>
        <input
          type="text"
          class="form-control"
          name="name"
          id="name"
          formControlName="name"
          placeholder="Name"
          aria-label="Name"
          title="Name"
          (focus)="isError = false"
          [ngClass]="{
            'is-valid': form.controls['name'].valid,
            'is-invalid':
              !form.controls['name'].valid && form.controls['name'].touched
          }"
        />
        <div class="invalid-feedback">Please enter your name.</div>
      </div>
      <div class="form-group">
        <label for="email" class="d-none">Email</label>
        <input
          type="email"
          class="form-control"
          name="email"
          id="email"
          formControlName="email"
          placeholder="Email"
          aria-label="Email"
          title="Email"
          (focus)="isError = false"
          [ngClass]="{
            'is-valid':
              form.controls['email'].valid && form.controls['email'].touched,
            'is-invalid':
              !form.controls['email'].valid && form.controls['email'].touched
          }"
        />
        <div class="invalid-feedback">
          <ng-container
            *ngIf="
              form.controls['email'].errors &&
              form.controls['email'].errors.required
            "
          >
            Please enter your email.
          </ng-container>
          <ng-container
            *ngIf="
              form.controls['email'].errors &&
              form.controls['email'].errors.patter
            "
          >
            Please enter a valid email.
          </ng-container>
        </div>
      </div>
      <div class="form-group">
        <label for="message" class="d-none">Message</label>
        <textarea
          class="form-control"
          name="message"
          id="message"
          formControlName="message"
          aria-label="Message"
          title="Message"
          placeholder="Message"
          rows="3"
          (focus)="isError = false"
          [ngClass]="{
            'is-valid':
              form.controls['message'].valid &&
              form.controls['message'].touched,
            'is-invalid':
              !form.controls['message'].valid &&
              form.controls['message'].touched
          }"
        ></textarea>
        <div class="invalid-feedback">Please enter a message.</div>
      </div>
      <button
        type="submit"
        class="btn btn-text-arrow-right"
        [disabled]="!form.valid"
      >
        Submit
      </button>
    </form>
  </div>
  <h3 class="font-weight-bold mt-3">
    For everything else, you can email to <br />
    <a class="inline-link" [href]="email.mailto">
      {{ email.text }}
    </a>
  </h3>
</div>
