import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EMAIL, SOCIAL_LINKS } from '../home/home.model';
import { HttpService } from './../home/home.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent {
  email = EMAIL;
  form: FormGroup;
  formInputs = {
    name: '',
    email: '',
    message: '',
  };
  isError = false;
  messageSent = '';
  links = SOCIAL_LINKS;

  constructor(private fb: FormBuilder, private httpService: HttpService) {
    this.form = this.fb.group({
      name: [null, Validators.required],
      email: [
        null,
        Validators.compose([
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
          Validators.required,
        ]),
      ],
      message: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
    });
  }

  addFormDetails(formDetails): void {
    this.formInputs.name = formDetails.name;
    this.formInputs.email = formDetails.email;
    this.formInputs.message = formDetails.message;
    this.httpService.post('contact', this.formInputs).subscribe(
      (response) => {
        if (response.result === 'succeeded') {
          this.messageSent = `Thank you ${this.formInputs.name} for stopping by.`;
        } else {
          this.isError = true;
          console.log(response);
        }
      },
      () => {
        this.isError = true;
      }
    );
  }
}
